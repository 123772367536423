<template>
    <Modal ref="form_ubigeo" dialog="modal-md">
        <div class="modal-header">
            <h4 class="modal-title">
                {{title_modal}}
            </h4>
        </div>

        <div class="modal-body">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <strong class='required'>Departamento</strong>
                            <select class="form-control idubigeo_temp_modal input-sm" id="coddepartamento" ref="departamento" @change="getProvincia($event)" v-model="formUbigeo.departamento">
                                <option v-for="(val, key) in departamentos" :key="key" :value="val.id_ubigeo" :data-dpto="val.cod_dpto" :data-prov="val.cod_prov">
                                    {{val.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <strong class='required'>Provincia</strong>
                            <select class="form-control idubigeo_temp_modal input-sm" id="codprovincia" ref="provincia" @change="getDistrito($event)" >
                                <option v-for="(val, key) in provincias" :key="key" :value="val.id_ubigeo" :data-dpto="val.cod_dpto" :data-prov="val.cod_prov">
                                    {{val.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <strong class='required'>Distrito</strong>
                            <select class="form-control idubigeo_temp_modal input-sm" id="coddistrito" ref="distrito" data-reload="">
                                <option v-for="(val, key) in distritos" :key="key" :value="val.id_ubigeo" :data-dpto="val.cod_dpto" :data-prov="val.cod_prov">
                                    {{val.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-footer">
            <button type="button" @click="selectedUbigeo" class="btn btn-primary cursor-pointer">Seleccionar</button>
            <button type="button" @click="close_ubigeo" class="btn btn-secondary" >Cancelar</button>
        </div>
    </Modal>
</template>

<script>
    import {mapMutations} from 'vuex';
    import Modal from '@/components/Modal.vue';

    export default {
        name: 'Ubigeo',
        components: {
            Modal,
		},
        props:{
            title_modal:{
                default:"SELECCIONE UBIGEO",
            },
            name_modal:{
                default:"",
            },
        },
        //emits: ["id_ubigeo"],
        mounted(){
            if(this.departamentos.length<1)
                this.getDepartamento();
            else{
                this.getProvincia();
            }
        },
        data(){
            return{
                formUbigeo:{
                    departamento:"17742",//SAN MARTIN
                    provincia:"",//18416 SAN MARTIN
                    distrito:"",//18417 TARAPOTO
                },
                departamentos:[],
                provincias:[],
                distritos:[],
                id_ubigeo:"",
                ubigeo_descripcion: "",
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            open_ubigeo(){
                this.$refs.form_ubigeo.open();
            },
            close_ubigeo(){
                this.$refs.form_ubigeo.close();
            },
            getDepartamento(){
                this.$http.get('tiendaVirtual/ubigeo/get_departamento').then((res)=>{
                    this.departamentos  = res.data;
                    if(this.departamentos.length>0){
                        setTimeout(()=>{
                            this.getProvincia();
                        }, 80);
                    }
                }).catch(e => {
                    console.log(e.response);
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            getProvincia(e){
                if(e)
                    this.setLoading(true);
                this.$http.get('tiendaVirtual/ubigeo/get_provincia?cod_dpto='+$("#coddepartamento option:selected").data("dpto")).then((res)=>{
                    this.provincias  = res.data;
                    if(this.provincias.length){
                        setTimeout(()=>{
                            $("#codprovincia").prop("selectedIndex", 0);
                        }, 80);

                        setTimeout(()=>{
                            this.getDistrito();
                        }, 80);
                    }
                }).catch(e => {
                    console.log(e.response);
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            getDistrito(e){
                if(e)
                    this.setLoading(true);
                
                this.$http.get('tiendaVirtual/ubigeo/get_distrito?cod_dpto='+$("#coddepartamento option:selected").data("dpto")+"&cod_prov="+$("#codprovincia option:selected").data("prov")).then((res)=>{
                    this.distritos  = res.data;
                    setTimeout(()=>{
                        $("#coddistrito").prop("selectedIndex", 0);
                    }, 80);
                }).catch(e => {
                    console.log(e.response);
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            selectedUbigeo(){
                let id_ubigeo               = $("#coddistrito").val();
                let departamento_selected   = $.trim($("#coddepartamento option:selected").text());
                let provincia_selected      = $.trim($("#codprovincia option:selected").text());
                let distrito_selected       = $.trim($("#coddistrito option:selected").text());

                this.$emit('dataUbigeo',{id_ubigeo:id_ubigeo, ubigeo:departamento_selected+" "+provincia_selected+" "+distrito_selected})
                this.close_ubigeo();
            },
            getUbigeoSelected(){
                //this.id_ubigeo;
                //this.$emit('dataUbigeo',{id_ubigeo:id_ubigeo, ubigeo:departamento_selected+" "+provincia_selected+" "+distrito_selected})
            }
        },
    }
</script>