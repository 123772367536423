<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Mi perfil</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="shopping_cart_page">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-5">
                        <div class="user-account-sidebar">
                            <aside class="user-info-wrapper pt-1">
                                <div class="user-info pt-3">
                                    <div class="user-avatar">
                                        <input type="file" name="file_avatar" id="file_avatar" ref="file_avatar" style="display: none;" accept="image/*" @change="cargar_avatar($event, 'prev_avatar')">
                                        <a class="edit-avatar" @click="buscar_avatar" href="javascript:void(0)">
                                            <i class="icofont icofont-edit"></i>
                                        </a>
                                        <img :src="misDatos.url_avatar??'/default_user.png'" id="prev_avatar" style="width:79px; height:79px;" alt="User">
                                    </div>
                                    
                                    <div class="user-data">
                                        <h4>{{misDatos.razon_social}}</h4>
                                        <span><i class="icofont icofont-ui-user"></i> {{misDatos.email}}</span>
                                    </div>
                                </div>
                            </aside>

                            <nav class="list-group">
                                <a :class="['list-group-item', (componentSelected.path=='MiPerfil')?'active cursor-default':'cursor-pointer']" @click="selectTab('MiPerfil', 'Mi Perfil')" href="javascript:void(0)">
                                    <i class="icofont icofont-ui-user fa-fw"></i>
                                    Mi perfil
                                </a>
                                <a :class="['list-group-item', (componentSelected.path=='MiDomicilio')?'active cursor-default':'cursor-pointer']" @click="selectTab('MiDomicilio', 'Mi Dirección')" href="javascript:void(0)">
                                    <i class="icofont icofont-location-pin fa-fw"></i>
                                    Mis direcciones
                                </a>

                                <a :class="['list-group-item', (componentSelected.path=='MisCompras')?'active cursor-default':'cursor-pointer']" @click="selectTab('MisCompras', 'Mis Compras')" href="javascript:void(0)">
                                    <span>
                                        <i class="icofont icofont-list fa-fw"></i>
                                        Mis Compras
                                    </span>
                                    <span class="badge badge-warning">{{(misDatos.ventas_online).length}}</span>
                                </a>
                                <a class="list-group-item" :href="'/carrito/'+sucursal_seleccionada">
                                    <i class="icofont icofont-shopping-cart"></i> Ir a carrito de compras 
                                </a>
                                <a class="list-group-item" href="javascript:void(0);" @click="cerrar_sesion">
                                    <i class="icofont icofont-logout fa-fw"></i> Salir 
                                </a>
                            </nav>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8 col-sm-7"  id="divProfile">
                        <div class="widget">
                            <div class="section-header">
                                <h5 class="heading-design-h5">{{componentSelected.name}}</h5>
                            </div>

                            <component :is="componentSelected.path" :datosPerfil="misDatos"></component>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import MiPerfil from '@/components/MiPerfil.vue';
    import MiDomicilio from '@/components/MiDomicilio.vue';
    import MisCompras from '@/components/MisCompras.vue';

    export default {
        name: 'perfil',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursal_seleccionada']),
        },
        components: {
            MiPerfil,
            MiDomicilio,
            MisCompras,
		},
        created(){
            this.loadObjPerfilUsuario();
        },
        mounted(){
            //console.log(this.$store.state.mi_cuenta);return;
            if(!this.$store.state.mi_cuenta.cli_id || !this.$store.state.mi_cuenta.api_token_carrito){
                this.cerrar_sesion();

                window.location.href = "/";
                return;
            }

            this.cargarMisDatos();
            document.title = "Mi perfil | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
            document.body.classList.add('error-page-main');
        },
        data(){
            return{
                misDatos:{
                    razon_social:"No definido",
                    numero_identificacion:"",
                    email:"anonimo@example.com",
                    telefono_movil:"",
                    direccion:"",
                    referencia_direccion:"",
                    ubigeo:"",
                    cod_ubigeo:"",
                    url_avatar:"/img/default_user.png",
                    ventas_local:[],
                    ventas_online:[],
                },
                componentSelected:{
                    name:'Mi Perfil',
                    path:'MiPerfil'
                },
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario']),
            buscar_avatar(){
                this.$refs.file_avatar.click();
            },
            cargar_avatar(e, id_previsualizar){
                let self = this;
                let files       =  e.target.files || e.dataTransfer.files;

                if (!files.length)
                    return;

                let file = files[0];
                let reader = new FileReader();

                reader.onload = (e) => {
                    self.guardar_avatar(e, id_previsualizar);
                };
                reader.readAsDataURL(file);
            },
            guardar_avatar(){
                let selfs = this;
                let formData = new FormData();
                    formData.append("cli_id", this.mi_cuenta.cli_id);
                    formData.append("cli_api_token", this.mi_cuenta.api_token_carrito);
                    formData.append('file', this.$refs.file_avatar.files[0]);

                selfs.setLoading(true);

                selfs.$http.post('tiendaVirtual/actualizar_avatar', formData,{ header:{'Content-Type' : 'multipart/form-data'}}).then((res)=>{
                    if(res.data.status){
                        selfs.misDatos.url_avatar    = res.data.data.url_avatar;

                        var img = document.getElementById(id_previsualizar);
                        img.src = e.target.result;

                        vm.image = e.target.result;
                    }
                }).catch(function(e){
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];

                            toastr.warning(mapErrors[field],'Error',{
                                positionClass: 'toast-top-left'
                            });
                        }
                    }
                }).finally(()=>{
                    selfs.setLoading(false);
                });
            },
            selectTab(tabComponent, nameTab){
                this.componentSelected.path = tabComponent;
                this.componentSelected.name = nameTab;
            },
            cargarMisDatos(){
                let selfs = this;
                let formData = new FormData();
                    formData.append("cli_id", this.$store.state.mi_cuenta.cli_id);
                    formData.append("cli_api_token", this.$store.state.mi_cuenta.api_token_carrito);
                    formData.append("sucursal", this.sucursal_seleccionada);

                selfs.setLoading(true);
                
                selfs.$http.post('tiendaVirtual/perfil', formData).then((res)=>{
                    if(res.data.status){
                        this.misDatos = res.data.data;
                        this.misDatos.ventas_local  = res.data.data.ventas_local??[];
                        this.misDatos.ventas_online = res.data.data.ventas_online??[];
                    }else{
                        this.cerrar_sesion();

                        window.location.href = "/";
                        return;
                    }
                    setTimeout(()=>{
                        selfs.setLoading(false);
                    }, 300);
                }).catch(function (e) {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        selfs.errorSolicitud = [];
                        selfs.input_email=false;
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];
                            selfs.errorSolicitud.push(mapErrors[field]);

                            toastr.warning(mapErrors[field], 'Error');
                        }
                    }
                });
            },
            cerrar_sesion(){
                this.$store.commit('borrarSesion');
                location.reload();
            },
        },
    }
</script>

<style scoped>
    .error-page-main{
      background-color: #a32121;
    }

    .cursor-default{
        cursor: default;
    }
</style>