<template>
    <div>
        <nav class="nav nav-tabs" id="myTab" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" >
                En linea <span class="badge badge-warning">{{(datosPerfil.ventas_online).length}}</span>
            </a>
            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" >Otros</a>
        </nav>

        <div class="tab-content" id="nav-tabContent" style="padding-top: 0.3rem;">
            <div class="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" aria-expanded="true">
                <div class="order-list-tabel-main">
                    <table class="datatabel table table-striped table-bordered order-list-tabel table-responsive" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Orden</th>
                                <th>Fecha</th>
                                <th>Sucursal</th>
                                <th>Estado</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr v-for="(val, key) in datosPerfil.ventas_online" :key="key">
                                <td>{{key+1}}</td>
                                <td>{{val.nro_operacion}}</td>
                                <td>{{val.fecha_es}}</td>
                                <td>{{val.sucursal}}</td>
                                <td>
                                    <span class="badge badge-success">{{val.etapa_operacion}}</span>
                                </td>
                                <td class="text-right">S/ {{val.total_importe}}</td>
                            </tr>

                            <tr v-if="(datosPerfil.ventas_online).length<1">
                                <td colspan="6"><center><i>No se encontró resultados</i></center></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" aria-expanded="false">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-info mb-1 text-justify pb-0 p-2">
                            <p>Si eres cliente frecuente en una de nuestras sucursales, y deseas ver las compras que hiciste, puedes visualizarlo aquí.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="order-list-tabel-main">
                            <table class="datatabel table table-striped table-bordered order-list-tabel table-responsive mb-0" id="compras_tienda" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th width="05%">#</th>
                                        <th width="12%">Comprobante</th>
                                        <th width="10%">Fecha</th>
                                        <th width="15%">Sucursal</th>
                                        <th width="20%">Asesor</th>
                                        <th width="10%">Total</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(val, key) in datosPerfil.ventas_local" :key="key">
                                        <td>{{key+1}}</td>
                                        <td>{{val.comprobante}}</td>
                                        <td>{{val.fecha_es}}</td>
                                        <td>{{val.sucursal}}</td>
                                        <td>{{val.asesor}}</td>
                                        <td class="text-right">{{number_format(val.total_importe, 2)}}</td>
                                    </tr>
                                    <tr v-if="(datosPerfil.ventas_local).length<1">
                                        <td colspan="6"><center><i>No se encontró resultados</i></center></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    export default {
        name: 'MiPerfil',
        computed:{
            ...mapState(['empresa']),
        },
        props: {
            datosPerfil: {},
        },
        mounted(){
        },
        data(){
            return{

            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario']),
        },
    }
</script>

<style scoped>
    .error-page-main{
      background-color: #a32121;
    }

    table.table#compras_tienda tbody tr td{
        padding:.25rem;
    }
</style>