<template>
    <div>
        <div class="row">
            <div class="col-sm-7">
                <div class="form-group">
                    <strong class="required">Ubigeo (Departamento / Provincia / Distrito)</strong>
                    <div class="input-group">
                        <input class="form-control" placeholder="Departamento - Provincia - Distrito" v-model="datosPerfil.ubigeo" ref="ubigeo" readonly type="text">
                        <div class="input-group-btn">
                            <button class="btn btn-danger cursor-pointer" ref="btn_ubigeo" @click="abrir_ubigeo">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <span v-if="formErrors.ubigeo!=''" class="clave text-danger" style="display:block;" role="alert">{{formErrors.ubigeo}}</span>
                </div>
            </div>

            <div class="col-sm-5">
                <div class="form-group">
                    <strong class="">Referencia de la dirección</strong>
                    <input class="form-control" placeholder="Al costado de la RENIEC" ref="referencia_direccion" v-model="datosPerfil.referencia_direccion" type="text">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <strong class="required">Dirección o domicilio</strong>
                    <textarea class="form-control" v-model="datosPerfil.direccion" ref="direccion" placeholder="Jr. Jimenez Pimentel #300"></textarea>
                    <span v-if="formErrors.direccion!=''" class="clave text-danger" style="display:block;" role="alert">{{formErrors.direccion}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 mt-4 text-right">
                <button type="button" class="btn btn-outline-danger btn-lg cursor-pointer" @click="actualizar_direccion"> Actualizar dirección </button>
            </div>
        </div>

        <Ubigeo ref="form_ubigeo" :title_modal="'Seleccione Ubigeo'" @dataUbigeo="ubigeo_seleccionado"></Ubigeo>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    import Ubigeo from '@/components/Ubigeo.vue';
    export default {
        name: 'MiDomicilio',
        computed:{
            ...mapState(['mi_cuenta','empresa']),
        },
        components: {
            Ubigeo,
		},
        props: {
            datosPerfil: {},
        },
        data(){
            return{
                formProfile:{
                    referencia_direccion:"",
                    direccion:"",
                },
                formErrors:{
                    cod_ubigeo:"",
                    referencia_direccion:"",
                    direccion:"",
                },
                id_ubigeo: "",
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario']),
            abrir_ubigeo(){
                this.$refs.form_ubigeo.open_ubigeo();
            },
            actualizar_direccion(){
                if(this.datosPerfil.ubigeo=="" || !this.datosPerfil.ubigeo){
                    this.formErrors.ubigeo = "El ubigeo es obligatorio";
                    this.$refs.btn_ubigeo.focus();
                    return;
                }

                if(this.datosPerfil.direccion=="" || !this.datosPerfil.direccion){
                    this.formErrors.direccion = "La dirección es obligatorio";
                    this.$refs.direccion.focus();
                    return;
                }

                let selfs = this;

                let formData = new FormData();
                    formData.append("cli_id", this.mi_cuenta.cli_id);
                    formData.append("cli_api_token", this.mi_cuenta.api_token_carrito);
                    formData.append("id_ubigeo", this.datosPerfil.cod_ubigeo);
                    formData.append("referencia_direccion", this.datosPerfil.referencia_direccion);
                    formData.append("direccion", this.datosPerfil.direccion);

                selfs.setLoading(true);
                selfs.$http.post('tiendaVirtual/actualizar_perfil', formData).then((res)=>{
                    if(res.data.status){
                        toastr.success("Mi perfil actualizado con éxito",'OK!!', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                    }
                }).catch(function(e){
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];

                            toastr.warning(mapErrors[field],'Error');
                        }
                    }
                }).finally(()=>{
                    selfs.setLoading(false);
                });
            },
            ubigeo_seleccionado(objUbigeo){
                this.datosPerfil.cod_ubigeo = objUbigeo.id_ubigeo;
                this.datosPerfil.ubigeo     = objUbigeo.ubigeo;
            },
        },
    }
</script>