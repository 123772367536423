<template>
    <form>
        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <strong class="required">DNI</strong>
                    <div class="input-group">
                        <input class="form-control" readonly placeholder="00000001" v-model="datosPerfil.numero_identificacion" maxlength="8" type="text">
                        <span v-if="formErrors.direccion!=''" class="clave text-danger" style="display:block;" role="alert">{{formErrors.direccion}}</span>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-8">
                <div class="form-group">
                    <strong class="required">Nombre Completo / Razon Social</strong>
                    <div class="input-group">
                        <input class="form-control" readonly v-model="datosPerfil.razon_social" :placeholder="datosPerfil.razon_social" type="text">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="icofont icofont-ui-user"></i>
                            </span>
                        </div>
                        <span v-if="formErrors.direccion!=''" class="clave text-danger" style="display:block;" role="alert">{{formErrors.direccion}}</span>
                    </div>
                </div>
            </div>

            <div class="col-sm-2">
                <div class="form-group">
                    <strong class="required">Telef. Móvil</strong>
                    <input class="form-control" v-model="datosPerfil.telefono_movil" ref="telefono_movil" @keypress="isNumberInteger($event)" placeholder="942000001" type="text" maxlength="9">
                    <span v-if="formErrors.telefono_movil!=''" class="clave text-danger" style="display:block;" role="alert">{{formErrors.telefono_movil}}</span>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-2">
                <strong>Cambiar clave</strong>
                <div class="onoffswitch">
                    <input type="checkbox" id="cambiar_clave" @change="cambiar_clave($event)" class="onoffswitch-checkbox" />
                    <label class="onoffswitch-label" for="cambiar_clave">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-group">
                    <strong class="">Clave actual</strong>
                    <div class="input-group">
                        <input class="form-control" value="**********" placeholder="******" disabled readonly type="text">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="icofont icofont-ui-lock"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-group">
                    <strong :class="[editar_clave?'required':'']">Nueva clave</strong>
                    <div class="input-group">
                        <input class="form-control" v-model="formProfile.clave" ref="nueva_clave" placeholder="******" :disabled="!editar_clave" :readonly="!editar_clave" type="text">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="icofont icofont-ui-lock"></i>
                            </span>
                        </div>
                    </div>
                    <span v-if="formProfile.clave=='' || formErrors.clave!=''" class="text-danger">{{formErrors.clave}}</span>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="form-group">
                    <strong :class="[editar_clave?'required':'']">Repita clave</strong>
                    <div class="input-group">
                        <input class="form-control" v-model="formProfile.re_clave" ref="re_nueva_clave" placeholder="******" :disabled="!editar_clave" :readonly="!editar_clave" type="text">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="icofont icofont-ui-lock"></i>
                            </span>
                        </div>
                    </div>
                    <span v-if="formProfile.re_clave=='' || formErrors.re_clave!=''" class="text-danger">{{formErrors.re_clave}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 mt-5 text-right">
                <button type="button" class="btn btn-outline-danger btn-lg cursor-pointer" @click="actualizar_perfil">Actualizar Perfil</button>
            </div>
        </div>
    </form>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';
    export default {
        name: 'MiPerfil',
        computed:{
            ...mapState(['mi_cuenta','empresa']),
        },
        props: {
            datosPerfil: {},
        },
        data(){
            return{
                formProfile:{
                    telefono_movil:"",
                    clave:"",
                    re_clave:"",
                },
                formErrors:{
                    telefono_movil:"",
                    clave:"",
                    re_clave:"",
                },
                editar_clave: false,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario']),
            cambiar_clave(event){
                this.editar_clave   = event.target.checked;
            },
            actualizar_perfil(){
                let selfs = this;

                if(this.datosPerfil.telefono_movil=="" || !this.datosPerfil.telefono_movil){
                    this.formErrors.telefono_movil = "Obligatorio";
                    this.$refs.telefono_movil.focus();
                    return;
                }

                if(this.editar_clave){
                    if(this.formProfile.clave==""){
                        this.formErrors.clave = "Ingrese la nueva clave";
                        this.$refs.nueva_clave.focus();
                        return;
                    }
                    this.formErrors.clave = "";

                    if(this.formProfile.re_clave==""){
                        this.formErrors.re_clave = "Repita la nueva clave";
                        this.$refs.re_nueva_clave.focus();
                        return;
                    }
                    this.formErrors.re_clave = "";

                    if(this.formProfile.clave!=this.formProfile.re_clave){
                        this.formErrors.re_clave    = "Las claves no coinciden";
                        this.formErrors.clave       = "Las claves no coinciden";
                        return;
                    }
                }

                let formData = new FormData();
                    formData.append("cli_id", this.mi_cuenta.cli_id);
                    formData.append("cli_api_token", this.mi_cuenta.api_token_carrito);
                    formData.append("telefono_movil", this.datosPerfil.telefono_movil);
                    formData.append("clave", this.formErrors.clave);
                    formData.append("re_clave", this.formErrors.re_clave);

                selfs.setLoading(true);
                selfs.$http.post('tiendaVirtual/actualizar_perfil', formData).then((res)=>{
                    if(res.data.status){
                        toastr.success("Mi perfil actualizado con éxito",'OK!!', { showDuration: 300, hideDuration: 12000, progressBar: true, closeButton:  true });
                    }
                }).catch(function(e){
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];

                            toastr.warning(mapErrors[field],'Error');
                        }
                    }
                }).finally(()=>{
                    selfs.setLoading(false);
                });
            },
        },
    }
</script>